import React, { useCallback, useContext, useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import TransitionLink, {TransitionPortal} from "gatsby-plugin-transition-link";
// import Link from 'gatsby-plugin-transition-link';
import { gsap } from "gsap";

// import Layout from "../components/layout"
import SEO from "../components/seo"
import { designerStyle } from "../components/designerStyle";

import Img from "gatsby-image";
import TransitionCover from "../components/TransitionCover/transitionCover";
import GlobalContext from "../context/globalContext";
import { duration, entryDelay, imageFadeDuration } from "../constants/componentConstants";
import Designer01Intro from "../components/Designer/Designer01Intro";
import Designer01Video from "../components/Designer/Designer01Video";

const Designer01Page = () => {
  const data = useStaticQuery(graphql`
  query {
    video01: file(relativePath: { eq: "designer01/video01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    image01: file(relativePath: { eq: "designer01/image01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    image02: file(relativePath: { eq: "designer01/image02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    profile01: file(relativePath: { eq: "designer01/profile01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    profile02: file(relativePath: { eq: "designer01/profile02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`);

  const global = useContext(GlobalContext);
  // const videoDelayValue = global.isLoading ? 1000 : 3000;
  // const videoDelay = useRef(videoDelayValue);

  useEffect(() => {
    global.setHideHeaderLogo(true);
    global.setEntryAnimation(entryAnimation);
    global.setExitAnimation(exitAnimation);
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, []);

  const commonLayer = useRef(null);
  const entryAnimation = useCallback(() => {
    console.log("entryAnimation");
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 100,
      })
      .to(commonLayer.current, {
        xPercent: 200,
        ease: "power2.in",
        duration: duration,
        delay: entryDelay,
        onComplete: () => {
          commonLayer.current.style.display = "none";
        }
      })
  }, []);
  const exitAnimation = useCallback(({ node }) => {
    console.log("exitAnimation");
    commonLayer.current.style.display = "block";
    gsap.timeline()
      .set(commonLayer.current, {
        xPercent: 0,
      })
      .to(commonLayer.current, {
        xPercent: 100,
        ease: "power2.out",
        duration: duration,
      })
      .to(node, {
        opacity: 0,
        duration: 0,
      })
  }, []);

  return (
    <>
      <SEO title="MIHARA YASUHIRO　三原康裕" />

      <Designer01Video />

      <Designer01Intro />

      <div css={designerStyle.workBlock}>
        <div css={designerStyle.workItem}>
          <div css={designerStyle.workImageBlock}>
            <div>
              <Img
                durationFadeIn={imageFadeDuration}
                fluid={data.image01.childImageSharp.fluid}
              />
            </div>
          </div>
          <div css={designerStyle.workTextBlock}>
            <h2>Subtitle Here Subtitle Here</h2>
            <div css={designerStyle.workSubTitle}>日本サブタイトル入る</div>
            <div css={designerStyle.workDescriptionJp}>
              次なる創造性と可能性をもたらす。ことができる。伝統を軸におきながらも、これまでに類を見ない異素材同士のレイヤリングと技術は、織物にフリッツ・ハンセンのエッグチェアに落とし込まれ、ファッションデザイナーならではのユニークな視点を垣間見るファッションデザイナーによって新たな織物のクリエイションを提案。三者三様の表情豊かなテキスタイルは、(ひきばく)、ビロード、綴織(つづれおり)の3つの技法に焦点をあて、世界で活躍が期待される3人の新鋭古くから現在まで匠な技術を世界に発信し続けている。同展では、和装で多用される伝統的な織物として、引箔京都・西陣をルーツとする川島織物セルコンは着物の帯や緞帳(どんちょう)、インテリアファブリックまでを手掛け、
            </div>
            <div css={designerStyle.workDescriptionEn}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.</div>
          </div>
        </div>

        <div css={designerStyle.workItem}>
          <div css={designerStyle.workImageBlock}>
            <div>
              <Img
                durationFadeIn={imageFadeDuration}
                fluid={data.image02.childImageSharp.fluid}
              />
            </div>
          </div>
          <div css={designerStyle.workTextBlock}>
            <h2>Subtitle Here Subtitle Here</h2>
            <div css={designerStyle.workSubTitle}>日本サブタイトル入る</div>
            <div css={designerStyle.workDescriptionJp}>
              次なる創造性と可能性をもたらす。ことができる。伝統を軸におきながらも、これまでに類を見ない異素材同士のレイヤリングと技術は、織物にフリッツ・ハンセンのエッグチェアに落とし込まれ、ファッションデザイナーならではのユニークな視点を垣間見るファッションデザイナーによって新たな織物のクリエイションを提案。三者三様の表情豊かなテキスタイルは、(ひきばく)、ビロード、綴織(つづれおり)の3つの技法に焦点をあて、世界で活躍が期待される3人の新鋭古くから現在まで匠な技術を世界に発信し続けている。同展では、和装で多用される伝統的な織物として、引箔京都・西陣をルーツとする川島織物セルコンは着物の帯や緞帳(どんちょう)、インテリアファブリックまでを手掛け、
            </div>
            <div css={designerStyle.workDescriptionEn}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.</div>
          </div>
        </div>
      </div>

      <div css={designerStyle.profileBlock}>
        <div css={designerStyle.profileImageBlock}>
          <Img
            durationFadeIn={imageFadeDuration}
            fluid={data.profile01.childImageSharp.fluid}
          />
        </div>

        <div css={designerStyle.profileNameBlock}>
          <div>
            <h2>MIHARA YASUHIRO</h2>
            <span>from MAISON MIHARA YASUHIRO</span>
          </div>
        </div>

        <p css={designerStyle.profileTextJp}>
          三原康裕<br />
          1972年長崎県生まれ。1994年、多摩美術大学デザイン学科テキスタイル学部に入学。在学中に独学でシューズ作りを始め、1996年に靴メーカーのバックアップにより「archi doom」を設立。1997年、大学卒業後に「MIHARA YASUHIRO」にブランド名を変更。2000SSよりウィメンズコレクション、メンズコレクションを国内外で発表。2016-17AWシーズンより、コレクションラインを「MAISON MIHARA YASUHIRO」としてリスタートさせた。

        </p>

        <div css={designerStyle.profileTextJp}>
          <div css={designerStyle.profileTitle}>伝統的な織物技術で描き出す“小宇宙”。</div>
          シューズデザイナーとしてキャリアをスタートさせ、その後、ウェアやスニーカーまで幅広く展開。ロンドンやパリでコレクションを発表するなど、第一線でファッション界を牽引し続けている。さまざまな企業やブランドとのコラボレーションを数多く手がけ、新たな素材の開発にも力を注ぐ。そんな三原は、織物への造詣が深いことでも知られているが、今回の川島織物セルコンとのコラボにおいて、織物そのものに「風化」を加えるという時間を超越した壮大なアイデアを思い描いた。紋（もん）ビロードと引箔（ひきばく）という伝統的な織物技術を併用し、「過去・現在・未来」という時間の流れを視覚的に表現。ノスタルジックでありながらも、未知なる可能性を秘めた独創的なチェアが完成した。
        </div>

        <div css={designerStyle.profileSubTitleJp}>── まず、オファーがきた時の心境を教えてください。</div>
        <p css={designerStyle.profileTextJp}>
          エッグチェア×川島織物という試みにとても興味を抱きました。そして、実際に何を表現すべきか？ 悩む日々の始まりだということも実感しました。
        </p>

        <div css={designerStyle.profileSubTitleJp}>── 今回、紋ビロードと引箔を用いて時間の経過を表現しています。「過去・現在・未来」をどのように織物へ視覚的に表現したのか、コンセプトを聞かせてください。</div>
        <p css={designerStyle.profileTextJp}>
          私たちは3次元の中で存在しているため、「現在という時間」を感じることは出来ても、「過去」は記憶、「未来」は予想でしかありません。どうしても私たちは「時間」という概念を経過的に理解することは出来ても剥離された時間の存在を理解することは出来ません。しかし、私はエッグチェアという造形から「小宇宙」のような感覚を感じます。それは茶室のような限られた空間が、何かで満たされるような感じで。そこで私はさまざまな時間軸を小さな宇宙に積み重ねたいと思ったのです。
        </p>

        <div css={designerStyle.profileSubTitleJp}>── テキスタイルや織物の知識が豊富でいらっしゃる三原さんが、今回のコラボで一番挑戦したかったことは何でしょうか？</div>
        <p css={designerStyle.profileTextJp}>
          さまざまな時間軸を蓄積させたようなテキスタイルを作るためには、視覚的なことだけではなく「精神」のレイヤーが必要だと感じました。それは、川島織物セルコンの歴史と職人の技術という時間の重なりを意味します。美しくても、ただ古びたようなテキスタイルではダメなのです。なぜなら、人々の情熱や希望というレイヤーが結果的に未来を表現するためです。
        </p>

        <div css={designerStyle.profileSubTitleJp}>── 川島織物セルコンの職人たちは、三原さんの生地に対する情熱やクリエーションに触発されたと言います。彼らとのやり取りを通じて、感じたことや新たに得たものは何でしょうか？</div>
        <p css={designerStyle.profileTextJp}>
          思っていた以上に、私の哲学を深く理解してくれたということに感動しました。
        </p>

        <div css={designerStyle.profileSubTitleJp}>── 出来上がった織物（最終試織）を見たときは、どう感じましたか？ </div>
        <p css={designerStyle.profileTextJp}>
          恐ろしく深い美しさでした。
        </p>

        <div css={designerStyle.profileSubTitleJp}>── 川島織物が長きにわたって継承してきた日本伝統の織物技術を、どのように若いデザイナーやクリエーターたちに伝えていきたいとお考えですか？</div>
        <p css={designerStyle.profileTextJp}>
          私は純粋に「挑戦」することを望んできました。率直に言うと、予想不能な「化学反応」を欲しているんです。それは、さまざまな異なるパズルが合わさるような世界。歴史や経験、生い立ちや生き方、人種や性別がぶつかり合うような世界。私たち人間はまだまだ何も知らない小さな存在です。だからこそ、挑戦するからこそ見えてくる広い世界があると感じています。デザイナーやクリエーターはその化学反応によって、継承すべき世界が伝わると思っています。「伝統」も元来は、イノベーティブな存在だと理解するからです。
        </p>

      </div>


      <TransitionPortal>
        <TransitionCover ref={commonLayer} />
      </TransitionPortal>
    </>
  )
}

export default Designer01Page
