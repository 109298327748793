import React, { useState } from "react";
import LogoImg from "../../images/logo.svg";
import { designerStyle } from "../designerStyle";
import SlideShowThumb from "../SlideShow/slideShowThumb";
import Img from "gatsby-image";
import { imageFadeDuration } from "../../constants/componentConstants";
import SlideShow from "../SlideShow/slideShow";
import { graphql, useStaticQuery } from "gatsby";

const Designer01Intro = () => {
  const data = useStaticQuery(graphql`
  query {
    slideImages: allFile(
      sort: { fields: [base] }
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "designer01/slides" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            fluid(maxWidth: 2880) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    slide01: file(relativePath: { eq: "designer01/slides/slide01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    slide02: file(relativePath: { eq: "designer01/slides/slide02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    slide03: file(relativePath: { eq: "designer01/slides/slide03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    image01: file(relativePath: { eq: "designer01/image01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    image02: file(relativePath: { eq: "designer01/image02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [startSlide, setStartSlide] = useState(0);
  const closeModal = () => {
    setIsModalOpen(false);
  }

  const openModal = (index = 0) => {
    setStartSlide(index);
    console.log(index);
    setIsModalOpen(true);
  }

  const openSlideShow = (index) => {
    openModal(index);
  }

  const slideShowThumbStyle = { blockStyle: designerStyle.slideShowThumbListItem };

  return (
    <div css={designerStyle.introBlock}>
      <div css={designerStyle.introLogo}>
        <LogoImg title="Weaver's QUEST" />
      </div>
      <div css={designerStyle.introNumber}>01</div>
      <div css={designerStyle.introInner}>
        <h1 css={designerStyle.introTitle}>by MIHARA YASUHIRO<span>三原康裕</span></h1>
        <div css={designerStyle.introData}>
          <div>
            <div css={designerStyle.introDescriptionEn}>
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu.
            </div>
            <div css={designerStyle.introDescriptionJp}>
              情に棹させば流される。智に働けば角が立つ。どこへ越しても住みにくいと悟った時、詩が生れて、画が 出来る。とかくに人の世は住みにくい。意地を通せば窮屈だ。とかくに人の世は住みにくい。 どこへ越しても住みにくいと悟った時、詩が生れて、画が出来る。意地を通せば窮屈だ。山路を
            </div>
          </div>
          <div>
            <div css={designerStyle.introListBlock}>
              <ul>
                <li>Client | Chukyo TV</li>
                <li>Agency | Dentu</li>
                <li>Edit | KOUKI HATANO</li>
                <li>Videographer | KOUKI HATANO</li>
                <li>Client | Chukyo TV</li>
                <li>Agency | Dentu</li>
                <li>Edit | KOUKI HATANO</li>
                <li>Videographer | KOUKI HATANO</li>
              </ul>
            </div>
          </div>
        </div>

        <div css={designerStyle.slideShowThumbList}>
          <SlideShowThumb onClick={openSlideShow} index={0} role="button" styles={slideShowThumbStyle}>
            <Img
              durationFadeIn={imageFadeDuration}
              fluid={data.slide01.childImageSharp.fluid}
            />
          </SlideShowThumb>
          <SlideShowThumb onClick={openSlideShow} index={1} role="button" styles={slideShowThumbStyle}>
            <Img
              durationFadeIn={imageFadeDuration}
              fluid={data.slide02.childImageSharp.fluid}
            />
          </SlideShowThumb>
          <SlideShowThumb onClick={openSlideShow} index={2} role="button" styles={slideShowThumbStyle}>
            <Img
              durationFadeIn={imageFadeDuration}
              fluid={data.slide03.childImageSharp.fluid}
            />
          </SlideShowThumb>
          <SlideShowThumb onClick={openSlideShow} index={0} role="button" styles={slideShowThumbStyle}>
            <div css={designerStyle.slideShowThumbListLabel}>
              <div>23 PHOTOS</div>
              <div>ENTER SLIDESHOW</div>
            </div>
          </SlideShowThumb>
        </div>
        <SlideShow
          open={isModalOpen}
          images={data.slideImages}
          startSlide={startSlide}
          closeModal={closeModal}
        />
      </div>
    </div>
  );
}

export default React.memo(Designer01Intro);
